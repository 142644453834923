import "./style.css";

// For testing
import "./img/Russ_Cooper.png";

// Add icons
import "./img/favicon.png";
import "./img/Upload-icon.png";
import "./img/envelope-solid.png";
import "./img/lock.png";
import "./img/rotate-right-solid.png";
import "./img/facebook.svg";
import "./img/RHM.png";

// Add poppy image file for generating canvas
import "./img/poppy_URL.png";
import "./img/Watermark.png";
import "./img/Watermark-wide.png";

// Add examples permitted for external use
import "./img/Brigadier Percy W Clark.png";
import "./img/Corporal John Wood.png";
import "./img/Gunner William C Welch.png";
import "./img/Lance Cpl Paul Muirhead.png";
import "./img/Private Albert Harvey.png";
import "./img/Private Thomas Parfrey.png";

// Real life images for home page
import "./img/Car-zoom-in.png";
import "./img/Car-zoom-out.png";
import "./img/Door-zoom-in.png";
import "./img/Door-zoom-out.png";
import "./img/Animal Poppy.png";
import "./img/Animal Poppy iteration.png";

// Add stories permitted for external use
import "./img/Brigadier Percy W Clark Story.png";
import "./img/Corporal John Wood Story.png";
import "./img/Gunner William C Welch Story.png";
import "./img/Lance Cpl Paul Muirhead Story.png";
import "./img/Private Albert Harvey Story.png";
import "./img/Private Thomas Parfrey Story.png";

// Add user created stories
import "./img/Frederick Maylin Story.png";

// Allow AWS Storage
import { Amplify, Storage } from "aws-amplify";
import awsconfig from "./aws-exports";
Amplify.configure(awsconfig);

// For downloading canvas blobs
import { saveAs } from "file-saver";

//
// On load of all pages
//

// Create unique ID for each image
import { v4 as uuidv4 } from "uuid";

// Text strings used in multiple places
const name = "name";
const date = "date";
const file = "file";
const location = "location";
const their_story = "Their Story";
const fontStyle = "px Arial, sans-serif";
const fontColor = "#ffffff";

const MY_POPPY = "My Personalised Poppy";
const MY_STORY = "My Personalised Story";

const BasePoppyWidth = 1600;
const BasePoppyHeight = 1880;

// Set up Contact Us button
const email = "rememberedheroes@outlook.com";
const contactElement = document.getElementById("contact-us");
contactElement.addEventListener(
  "click",
  function () {
    // Copy the text inside the text field
    navigator.clipboard.writeText(email);

    // Alert the copied text
    alert("Copied email\n\nrememberedheroes@outlook.com\n\n to clipboard");
  },
  false
);

//
// Cookie Functions
//

// Cookie Variables
const Cookie_Client_ID = "Client ID";
const Cookie_Name = "Name";
const Cookie_Fell_Date = "date fallen";
const Cookie_Location = "Location";
const Cookie_Their_Story = "Their Story";
const Cookie_Set_jpg = "Set jpg";
const Cookie_Set_png = "Set png";
const Cookie_Expire_Days = 31;

const cookiesArr = [
  Cookie_Name,
  Cookie_Fell_Date,
  Cookie_Location,
  Cookie_Their_Story,
  Cookie_Set_jpg,
  Cookie_Set_png,
];

// Set a cookie with the given name, value, and expiration date
function setCookie(name, value, days) {
  const encodedValue = encodeURIComponent(value);
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = `${name}=${encodedValue || ""}${expires}; path=/`;
}

// Get the value of a cookie with the given name
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return null;
}

// Set cookies on initial load
if (!getCookie(Cookie_Client_ID)) {
  new_Client_ID();
}
// Add cookies if they do not exist
cookiesArr.forEach((element) => {
  if (!getCookie(element)) {
    setCookie(element, "", Cookie_Expire_Days);
  }
});

// On loading of Home Page
if (document.body.classList.contains("home-page")) {
  function hoverHomeImg(element, newImgName) {
    element.src = "/img/" + newImgName;
  }

  const doorElement = document.getElementById("door-img");
  const carElement = document.getElementById("car-img");

  doorElement.addEventListener("mouseenter", () =>
    hoverHomeImg(doorElement, "Door-zoom-out.png")
  );
  doorElement.addEventListener("mouseleave", () =>
    hoverHomeImg(doorElement, "Door-zoom-in.png")
  );

  carElement.addEventListener("mouseenter", () =>
    hoverHomeImg(carElement, "Car-zoom-out.png")
  );
  carElement.addEventListener("mouseleave", () =>
    hoverHomeImg(carElement, "Car-zoom-in.png")
  );

  const scaleFactor = 5;
  const CircleX = 100 * scaleFactor;
  const CircleY = 100 * scaleFactor;
  const offscreenCanvas = DrawWhiteCircleOffscreen(scaleFactor);

  const canvas = document.getElementById("merged-image");
  const ctx = canvas.getContext("2d");

  let _file;
  let _img;

  if (getCookie(Cookie_Set_jpg) !== "") {
    document.getElementById("user-input-img-label").classList.add("hidden");
    getItemFromStorage(".jpg").then((result) => {
      _img = new Image();

      _img.onload = function () {
        _file = "";
      };

      _img.src = result;
      _img.crossOrigin = "anonymous";
    });
  }

  const poppyBackground = new Image();
  const Watermark = new Image();
  const wideWatermark = new Image();

  poppyBackground.src = "/img/poppy_URL.png";
  Watermark.src = "/img/Watermark.png";
  wideWatermark.src = "/img/Watermark-wide.png";

  const baseImages = [poppyBackground, Watermark, wideWatermark];
  let imagesLoaded = 0;

  baseImages.forEach((image) => {
    image.onload = imageLoaded;
  });

  function imageLoaded() {
    imagesLoaded++;

    if (imagesLoaded === baseImages.length) {
      let userPoppy;
      if (getCookie(Cookie_Set_png) !== "") {
        getItemFromStorage(".png")
          .then((result) => {
            const storage_canvas = new Image();
            storage_canvas.src = result;
            storage_canvas.crossOrigin = "anonymous";

            storage_canvas.onload = function () {
              userPoppy = drawInitialPoppy(
                Watermark,
                ctx,
                offscreenCanvas,
                CircleX,
                CircleY,
                canvas,
                storage_canvas
              );
            };
            storage_canvas.onerror = function () {
              userPoppy = drawInitialPoppy(
                Watermark,
                ctx,
                offscreenCanvas,
                CircleX,
                CircleY,
                canvas,
                null
              );
            };
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        userPoppy = drawInitialPoppy(
          Watermark,
          ctx,
          offscreenCanvas,
          CircleX,
          CircleY,
          canvas,
          null
        );
      }
      drawUserStory(false, undefined, userPoppy, wideWatermark);
    }
  }

  const nameInputElement = document.getElementById("name-input");
  nameInputElement.value = getCookie(Cookie_Name);

  let timerId;
  const TIMER_DELAY = 200;

  nameInputElement.addEventListener("input", (event) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      handleInputChange(event, name);
    }, TIMER_DELAY);
  });
  nameInputElement.addEventListener("change", () => {
    handleSubmit();
  });

  const dateInputElement = document.getElementById("date-input");
  dateInputElement.value = getCookie(Cookie_Fell_Date);
  dateInputElement.addEventListener("input", (event) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      handleInputChange(event, date);
    }, TIMER_DELAY);
  });
  dateInputElement.addEventListener("change", () => {
    handleSubmit();
  });

  const locationInputElement = document.getElementById("location-input");
  locationInputElement.value = getCookie(Cookie_Location);
  locationInputElement.addEventListener("input", (event) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      handleInputChange(event, location);
    }, TIMER_DELAY);
  });
  locationInputElement.addEventListener("change", () => {
    handleSubmit();
  });

  const fileInputElement = document.getElementById("user-input-img");
  fileInputElement.addEventListener("input", (event) => {
    handleInputChange(event, file);
  });

  const theirStoryElement = document.getElementById("their-story");
  theirStoryElement.value = getCookie(Cookie_Their_Story);
  theirStoryElement.addEventListener("input", (event) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      handleInputChange(event, their_story);
    }, TIMER_DELAY);
  });
  theirStoryElement.addEventListener("change", () => {
    handleSubmit(their_story);
  });

  const reCreateElement = document.getElementById("reCreate");
  reCreateElement.addEventListener("click", () => {
    new_Client_ID();
    updateBuyButton(true);
    resetOnScreenElements();
    drawInitialPoppy(
      Watermark,
      ctx,
      offscreenCanvas,
      CircleX,
      CircleY,
      canvas,
      null
    );
  });

  const consentCheckbox = document.getElementById("consent-checkbox");
  consentCheckbox.addEventListener("change", () => {
    updateBuyButton(false);
  });

  const selector = document.getElementById("basket-selector");
  selector.addEventListener("change", () => {
    updateBuyButton(true);
  });

  function handleSubmit(field) {
    const user_ID = getCookie(Cookie_Client_ID);
    switch (field) {
      case their_story:
        Storage.put(user_ID + ".txt", getCookie(Cookie_Their_Story));
        break;

      default:
        // Redraw and Save the poppy to storage
        drawUserInputPoppy(
          user_ID,
          baseImages,
          scaleFactor,
          canvas,
          ctx,
          offscreenCanvas,
          CircleX,
          CircleY,
          _file,
          _img,
          true
        );
        break;
    }
  }

  function handleInputChange(event, field) {
    const user_ID = getCookie(Cookie_Client_ID);

    switch (field) {
      case name:
        setCookie(Cookie_Name, event.target.value, Cookie_Expire_Days);
        break;
      case date:
        setCookie(Cookie_Fell_Date, event.target.value, Cookie_Expire_Days);
        break;
      case location:
        setCookie(Cookie_Location, event.target.value, Cookie_Expire_Days);
        break;
      case file:
        const reader = new FileReader();
        reader.onload = function () {
          _img = new Image();
          _img.onload = function () {
            _file = event.target.files[0];
            const userPoppy = drawUserInputPoppy(
              user_ID,
              baseImages,
              scaleFactor,
              canvas,
              ctx,
              offscreenCanvas,
              CircleX,
              CircleY,
              _file,
              _img,
              true
            );
            drawUserStory(false, undefined, userPoppy, wideWatermark);
          };
          _img.src = reader.result;
        };
        reader.readAsDataURL(event.target.files[0]);
        document.getElementById("user-input-img-label").classList.add("hidden");
        setCookie(Cookie_Set_jpg, "true", Cookie_Expire_Days);
        saveCanvasToStorage(canvas);
        break;
      case their_story:
        setCookie(Cookie_Their_Story, event.target.value, Cookie_Expire_Days);
        break;
      default:
        break;
    }

    if (
      field === name ||
      field === date ||
      field === location ||
      field === their_story
    ) {
      const userPoppy = drawUserInputPoppy(
        user_ID,
        baseImages,
        scaleFactor,
        canvas,
        ctx,
        offscreenCanvas,
        CircleX,
        CircleY,
        _file,
        _img,
        false
      );
      drawUserStory(false, undefined, userPoppy, wideWatermark);
    }
  }

  function resetOnScreenElements() {
    const buyElement = document.getElementById("buy-now");

    document.getElementById("user-input-img-label").classList.remove("hidden");

    resetElement(nameInputElement);
    resetElement(dateInputElement);
    resetElement(locationInputElement);
    resetElement(theirStoryElement);
    resetElement(fileInputElement);

    cookiesArr.forEach((element) => {
      setCookie(element, "", Cookie_Expire_Days);
    });

    _file = undefined;
    _img = undefined;

    buyElement.classList.add("disabled");

    drawUserStory(false, "");
  }

  function resetElement(element) {
    element.value = "";
  }
}

// On loading of Animals Page
if (document.body.classList.contains("animals-page")) {
  const selector = document.getElementById("basket-selector");
  setCookie(Cookie_Name, "Animal Poppy", Cookie_Expire_Days);
  selector.addEventListener("change", () => {
    updateBuyButton(true);
  });
}

// On loading of Downloads Page
if (document.body.classList.contains("download-page")) {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let user_ID = params.User_ID;

  if (user_ID === null) {
    user_ID = getCookie(Cookie_Client_ID);
    window.location.href = window.location.href + `?User_ID=${user_ID}`;
  }

  drawPoppyFromStorage(user_ID);
  drawUserStory(true, undefined, undefined, undefined, user_ID);
}

function drawUserStory(
  isDownloadPage,
  updatedStory,
  userPoppy,
  wideWatermark,
  user_ID
) {
  const userStoryElement = document.getElementById("user-story");
  const userStoryImg = document.getElementById("user-story-img");
  const ctx = userStoryImg.getContext("2d");
  let textOutput = "";

  // Story provided, use that
  if (updatedStory) {
    textOutput = updatedStory;
    // Story omitted, fallback to cookies
  } else {
    textOutput = getCookie(Cookie_Their_Story);
  }

  // Poppy image provided, use that and generate story canvas
  if (userPoppy) {
    drawStoryCanvas(
      userPoppy,
      textOutput,
      userStoryImg,
      userStoryElement,
      isDownloadPage
    );
    if (wideWatermark) {
      addWatermark(ctx, userStoryImg, wideWatermark);
    }
    // Reset story on home page if no poppy img set
  } else if (getCookie(Cookie_Set_png) === "" && !isDownloadPage) {
    ctx.clearRect(0, 0, userStoryImg.width, userStoryImg.height);
  } else {
    // Fallback to story in storage
    getItemFromStorage("-story.png", user_ID)
      .then((result) => {
        fetch(result)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onloadend = function () {
              // Load the image using base64 encoding
              const base64data = reader.result;
              const storagePoppy = new Image();
              storagePoppy.onload = function () {
                ctx.drawImage(storagePoppy, 0, 0);
                if (isDownloadPage) {
                  // Set the href and download name of user poppy
                  setBase64ImgDownload(base64data, userStoryElement, MY_STORY);
                }
                if (wideWatermark) {
                  addWatermark(ctx, userStoryImg, wideWatermark);
                }
              };
              storagePoppy.src = base64data;
            };
            reader.readAsDataURL(blob);
          });
      })

      .catch((error) => {
        console.error("Error retrieving png content:", error);
      });
  }
}

function drawPoppyFromStorage(user_ID) {
  const userPoppyElement = document.getElementById("user-poppy");
  const userPoppyImg = document.getElementById("user-poppy-img");

  getItemFromStorage(".png", user_ID)
    .then((result) => {
      fetch(result)
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            // Load the image using base64 encoding
            const base64data = reader.result;
            const storagePoppy = new Image();
            storagePoppy.onload = function () {
              userPoppyImg.src = result;
              // Set the href and download name of user poppy
              setBase64ImgDownload(base64data, userPoppyElement, MY_POPPY);
            };
            storagePoppy.src = base64data;
          };
          reader.readAsDataURL(blob);
        });
    })

    .catch((error) => {
      console.error("Error retrieving png content:", error);
    });
}

async function drawStoryCanvas(
  LHS_Poppy,
  textContent,
  userStoryImg,
  userStoryElement,
  downloadPage
) {
  const canvas = userStoryImg;
  const ctx = canvas.getContext("2d");
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  const fontSize = 100;
  const maxWidths = Array(17).fill(BasePoppyWidth * 0.9);

  // Need to edit HTML and CSS if you edit this
  const borderSize = 100;
  // HTML width = 3300px + borderSize, height = 1880px + borderSize
  // CSS width = 660px + borderSize / 5, height = 376px + borderSize / 5

  // White gradient background if supported
  const grd = ctx.createLinearGradient(0, 0, 0, canvas.height);
  grd.addColorStop(0, "#8f8f8f");
  grd.addColorStop(1, "white");
  ctx.fillStyle = grd;

  // Draw rounded background if supported
  drawRoundRect(
    ctx,
    borderSize / 4,
    borderSize / 4,
    canvas.width - borderSize / 2,
    canvas.height - borderSize / 2,
    0
  );
  // Fill in background
  ctx.fill();

  //
  // Zig Zag Lines in grey
  //

  // Hill
  drawLine(
    ctx,
    canvas.width * 0.4,
    canvas.height * 0.8,
    canvas.width * 0.8,
    canvas.height * 0.5,
    canvas.width * 1,
    canvas.height * 0.65
  );

  // Valley
  drawLine(
    ctx,
    canvas.width * 0.4,
    canvas.height * 0.4,
    canvas.width * 0.7,
    canvas.height * 0.95,
    canvas.width * 1,
    canvas.height * 0.8
  );

  // Top bar
  drawLine(
    ctx,
    canvas.width * 0.4,
    canvas.height * 0.2,
    canvas.width * 0.6,
    canvas.height * 0.3,
    canvas.width * 1,
    canvas.height * 0.1
  );

  // Red border
  ctx.beginPath();
  ctx.strokeStyle = "red";
  ctx.lineWidth = 50;
  drawRoundRect(ctx, 0, 0, canvas.width, canvas.height, borderSize * 0.6);
  ctx.stroke();

  // Draw poppy on LHS on canvas
  ctx.drawImage(
    LHS_Poppy,
    borderSize / 2,
    borderSize / 2,
    BasePoppyWidth,
    BasePoppyHeight
  );

  // Reset styles for text
  ctx.fillStyle = "black";
  ctx.strokeStyle = "black";
  ctx.lineWidth = 1;

  addTextToCanvas(
    ctx,
    textContent,
    maxWidths,
    borderSize,
    fontSize,
    true,
    BasePoppyWidth * 0.56
  );

  if (downloadPage) {
    // Set the href and download name of user story
    setBase64ImgDownload(userStoryImg.toDataURL(), userStoryElement, MY_STORY);
  }

  saveCanvasToStorage(canvas, "-story.png");
}

function setBase64ImgDownload(base64Data, element, downloadName) {
  // Remove the data:image/...;base64, prefix
  const dataStartIndex = base64Data.indexOf(",") + 1;
  const cleanBase64Data = base64Data.substring(dataStartIndex);

  // Convert the Base64 data to binary
  const binaryData = atob(cleanBase64Data);

  // Create a Uint8Array to hold the binary data
  const byteArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([byteArray], { type: "image/png" }); // Change type accordingly

  // Generate a URL for the Blob
  const blobUrl = URL.createObjectURL(blob);

  // Use File Saver for browser cross compatability
  element.addEventListener("click", () => {
    saveAs(blobUrl, `${downloadName}.png`);
  });
}

function drawRoundRect(ctx, x, y, w, h, radii) {
  if (CanvasRenderingContext2D.prototype.roundRect === undefined) {
    //Default to no rounded corners
    ctx.rect(x, y, w, h);
  } else {
    ctx.roundRect(x, y, w, h, radii);
  }
}

// On loading of Blog Page
if (document.body.classList.contains("blog-page")) {
  const source = document.getElementById("blog-search");
  const headings = document.querySelectorAll("h2");

  const inputHandler = function (e) {
    headings.forEach((heading) => {
      const headingText = heading.textContent.toLowerCase();
      if (headingText.includes(e.target.value.toLowerCase())) {
        heading.parentElement.style.display = "block";
      } else {
        heading.parentElement.style.display = "none";
      }
    });
  };

  source.addEventListener("input", inputHandler);

  const randomPoppyElement = document.getElementById("random-poppy");

  randomPoppyElement.onclick = function () {
    // Hide all poppies
    headings.forEach((element) => {
      element.parentElement.style.display = "none";
    });

    const rng = getRandomInt(headings.length);
    // Show random poppy
    headings[rng].parentElement.style.display = "block";
  };
}

function getRandomInt(maxVal) {
  return Math.floor(Math.random() * maxVal);
}

const purchaseURLs = {
  // For live payments
  Digital: "https://buy.stripe.com/aEU00Iea4gx55vWbIU",
  A3_Sticker: "https://buy.stripe.com/aEU00I1nibcL7E400g",
  A4_Sticker: "https://buy.stripe.com/14k00I2rma8H2jK5kz",
  A3_Magnet: "https://buy.stripe.com/bIY4gY5Dya8H2jKfZc",
  A4_Magnet: "https://buy.stripe.com/fZefZG5Dy5Sr8I86oB",
};

function addUserID(url) {
  const user_ID = getCookie(Cookie_Client_ID);
  let shareConsent;
  const consentCheckbox = document.getElementById("consent-checkbox");
  if (consentCheckbox) {
    shareConsent = consentCheckbox.checked;
  } else {
    //Default to no consent if unsure
    shareConsent = false;
  }

  const paymentLink = `${url}?client_reference_id=${user_ID}_Share_${shareConsent}`;
  return paymentLink;
}

function updateBuyButton(showButton) {
  const buyElement = document.getElementById("buy-now");
  const selector = document.getElementById("basket-selector");

  if (showButton) {
    buyElement.classList.remove("hidden");
  }

  switch (selector.value) {
    case "digital":
      buyElement.href = addUserID(purchaseURLs.Digital);
      break;

    case "A3-Sticker":
      buyElement.href = addUserID(purchaseURLs.A3_Sticker);
      break;

    case "A4-Sticker":
      buyElement.href = addUserID(purchaseURLs.A4_Sticker);
      break;

    case "A3-Magnet":
      buyElement.href = addUserID(purchaseURLs.A3_Magnet);
      break;

    case "A4-Magnet":
      buyElement.href = addUserID(purchaseURLs.A4_Magnet);
      break;

    default:
      break;
  }
}

// Convert canvas data to s3 storage format
function dataURItoBlob(dataURI) {
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/png" });
}

//
// Cookies
//

// Return new client ID and save to cookie
function new_Client_ID() {
  // New client ID is always 36 characters
  const new_ID = uuidv4();
  setCookie(Cookie_Client_ID, new_ID, Cookie_Expire_Days);
  return new_ID;
}

//
// Home Page functions
//

// Canvas

function DrawWhiteCircleOffscreen(scaleFactor) {
  // Set up dimensions of offscreen canvas
  const CircleRadius = 60 * scaleFactor;

  // Create an off-screen canvas
  const offscreenCanvas = document.createElement("canvas");
  offscreenCanvas.width = 2 * CircleRadius;
  offscreenCanvas.height = 2 * CircleRadius;
  const offscreenCtx = offscreenCanvas.getContext("2d");

  // Draw a white circle on the off-screen canvas, the exact size needed
  offscreenCtx.fillStyle = "white";
  offscreenCtx.beginPath();
  offscreenCtx.arc(CircleRadius, CircleRadius, CircleRadius, 0, 2 * Math.PI);
  offscreenCtx.fill();

  return offscreenCanvas;
}

function drawInitialPoppy(
  Watermark,
  ctx,
  offscreenCanvas,
  CircleX,
  CircleY,
  canvas,
  storage_canvas
) {
  // Reset the canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw white backround circle where we want on the canvas
  ctx.drawImage(offscreenCanvas, CircleX, CircleY);

  if (storage_canvas) {
    // Draw previously saved image from storage
    ctx.drawImage(storage_canvas, 0, 0, canvas.width, canvas.height);

    // Add watermark
    addWatermark(ctx, canvas, Watermark);
  }
}

function drawUserInputPoppy(
  user_ID,
  baseImages,
  scaleFactor,
  canvas,
  ctx,
  offscreenCanvas,
  CircleX,
  CircleY,
  _file,
  _img,
  saveToStorage
) {
  const buyElement = document.getElementById("buy-now");
  buyElement.classList.remove("disabled");

  const _name = getCookie(Cookie_Name);
  const _date = getCookie(Cookie_Fell_Date);
  const _location = getCookie(Cookie_Location);

  // Reset the canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw white backround circle where we want on the canvas
  ctx.drawImage(offscreenCanvas, CircleX, CircleY);

  if (_file !== undefined) {
    const maxFileSize = 25;
    // Check filesize in MegaBytes
    const filesize = (_file.size / 1024 / 1024).toFixed(2);
    if (filesize >= maxFileSize) {
      alert(
        "File size: " + filesize + "MB must be less than " + maxFileSize + "MB"
      );
      return;
    }

    if (_file !== "") {
      Storage.put(user_ID + ".jpg", _file, { contentType: "image/jpeg" });
    }

    // Calculate the aspect ratio of the uploaded image and offscreen canvas
    const imageAspectRatio = _img.width / _img.height;
    const canvasAspectRatio = offscreenCanvas.width / offscreenCanvas.height;

    // Determine the coordinates and dimensions of the cropped image
    let img_width = offscreenCanvas.width,
      img_height = offscreenCanvas.height,
      x_pos = CircleX,
      y_pos = CircleY;

    if (imageAspectRatio < canvasAspectRatio) {
      // The image is taller than the canvas
      img_height = offscreenCanvas.height / imageAspectRatio;
      y_pos = y_pos + offscreenCanvas.height / 2 - img_height / 2;
    } else {
      // The image is wider than the canvas
      img_width = offscreenCanvas.width * imageAspectRatio;
      x_pos = x_pos + offscreenCanvas.width / 2 - img_width / 2;
    }
    // Draw user uploaded image
    ctx.drawImage(_img, x_pos, y_pos, img_width, img_height);
  }

  // Draw base poppy
  ctx.drawImage(baseImages[0], 0, 0, canvas.width, canvas.height);

  // Add text to canvas
  addAllTextToCanvas(ctx, _name, _date, _location, scaleFactor);

  if (saveToStorage) {
    saveCanvasToStorage(canvas);
  }

  // Create a copy of the original canvas
  const hiddenCanvas = canvas.cloneNode();

  // Get the 2D context of the hidden canvas
  const hiddenCtx = hiddenCanvas.getContext("2d");

  // Draw the content of the original canvas onto the hidden canvas
  hiddenCtx.drawImage(canvas, 0, 0);

  // Add watermark before displaying to user
  addWatermark(ctx, canvas, baseImages[1]);

  return hiddenCanvas;
}

// Canvas - watermark
function addWatermark(ctx, canvas, img) {
  // Set opacity
  ctx.globalAlpha = 0.7;

  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  // Add watermark

  // Reset opacity
  ctx.globalAlpha = 1;
}

// Canvas - text

function addAllTextToCanvas(ctx, name, date, location, scaleFactor) {
  const mainFontSize = 30 * scaleFactor;

  ctx.font = mainFontSize + fontStyle;
  ctx.fillStyle = fontColor;
  ctx.strokeStyle = fontColor;
  ctx.textAlign = "center";

  const lest = "LEST WE FORGET";
  const topTextmaxWidths = [200 * scaleFactor, 250 * scaleFactor];
  var topTextOffset = 20 * scaleFactor;

  // Draw name as text
  addTextToCanvas(
    ctx,
    name,
    topTextmaxWidths,
    topTextOffset,
    mainFontSize,
    true,
    0
  );

  // Draw date fallen
  addTextToCanvas(
    ctx,
    date,
    [300 * scaleFactor],
    212 * scaleFactor,
    22 * scaleFactor,
    false,
    0
  );

  // Draw Location
  addTextToCanvas(
    ctx,
    location,
    [300 * scaleFactor],
    235 * scaleFactor,
    22 * scaleFactor,
    false,
    0
  );

  // Draw Lest We Forget
  addTextToCanvas(
    ctx,
    lest,
    [320 * scaleFactor],
    265 * scaleFactor,
    mainFontSize,
    true,
    0
  );
}

// Fill text given onto canvas
function drawText(isBold, canvas, name, x_pos, y_pos, maxWidth) {
  canvas.fillText(name, x_pos, y_pos, maxWidth);
  if (isBold) {
    canvas.strokeText(name, x_pos, y_pos, maxWidth);
  }
}

// Return array of strings to allow word wrap of text
function getLines(ctx, text, maxWidths) {
  // Create an array of words for each new line
  const words = text.trim().split(" ");
  // Array to store the lines of wrapped text
  const lines = [];
  // Variable to track the current line text
  let currentLine = "";
  let j = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    // We had " \n ", so can just push a new line
    if (word !== "\n") {
      // Temporary variable to check for newline characters in the word
      let checkWord = word;
      let newLinePos = checkWord.indexOf("\n");

      // Check for new line characters within the word
      while (newLinePos >= 0) {
        const endOfLine = checkWord.substring(0, newLinePos);
        if (currentLine.length > 0) {
          lines.push(currentLine + " " + endOfLine);
        } else {
          lines.push(endOfLine);
        }

        j++;
        currentLine = "";
        const startOfNewLine = checkWord.substring(newLinePos + 1);
        newLinePos = startOfNewLine.indexOf("\n");
        checkWord = startOfNewLine;
      }

      const width = ctx.measureText(currentLine + " " + checkWord).width;

      if (width < maxWidths[j]) {
        // Add a space if the current line is not empty
        currentLine += currentLine.length === 0 ? checkWord : " " + checkWord;
      } else {
        lines.push(currentLine);
        j++;
        currentLine = word;
      }
    } else {
      lines.push(currentLine);
      j++;
      currentLine = "";
    }
  }

  // Push any remaining text in the current line to the lines array
  if (currentLine.trim() !== "") {
    lines.push(currentLine);
  }

  if (j >= maxWidths.length) {
    let output = `Unable to render:\n\n`;
    for (let i = maxWidths.length; i <= j; i++) {
      output = output + " " + lines[i];
    }
    output = output + "\n\nMax input length exceeded";
    window.alert(output);
  }

  return lines.slice(0, maxWidths.length);
}

function saveCanvasToStorage(canvas, suffix = ".png") {
  const user_ID = getCookie(Cookie_Client_ID);
  const dataUrl = canvas.toDataURL("image/png");
  const blobData = dataURItoBlob(dataUrl);
  Storage.put(user_ID + suffix, blobData);
  setCookie(Cookie_Set_png, "true", Cookie_Expire_Days);
}

async function getItemFromStorage(type, user_ID = getCookie(Cookie_Client_ID)) {
  const filename = user_ID + type;

  try {
    // Check if file exists
    const metaData = await Storage.getProperties(filename);
    if (metaData) {
      // Get file from storage
      const buyElement = document.getElementById("buy-now");
      if (buyElement) {
        buyElement.classList.remove("disabled");
      }
      const result = await Storage.get(filename);
      return result;
    }
  } catch (error) {
    switch (type) {
      case ".jpg":
        console.error("No backup image found in storage", error);
        break;

      case ".png":
        console.error("No canvas found in storage", error);
        break;

      case ".txt":
        console.error("No story found in storage", error);
        break;

      default:
        console.error(error);
        break;
    }
  }
  return null;
}

// Text

function addTextToCanvas(
  canvas,
  text,
  maxWidths,
  topOffset,
  fontSize,
  isBold,
  leftOffset
) {
  canvas.font = fontSize + fontStyle;
  const lines = getLines(canvas, text, maxWidths);
  if (lines.length === 1) {
    topOffset = topOffset + fontSize / 2;
  }
  lines.forEach((element, i) => {
    drawText(
      isBold,
      canvas,
      element,
      BasePoppyWidth / 2 + leftOffset,
      fontSize * (i + 1) + topOffset,
      BasePoppyWidth
    );
  });
}

// Lines
function drawLine(ctx, x1, y1, x2, y2, x3, y3, stroke = "#cfcfcf", width = 12) {
  // start a new path
  ctx.beginPath();

  // place the cursor from the point the line should be started
  ctx.moveTo(x1, y1);

  // draw a line from current cursor position to the provided x,y coordinate
  ctx.lineTo(x2, y2);

  // Repeat
  ctx.lineTo(x3, y3);

  // set strokecolor
  ctx.strokeStyle = stroke;

  // set lineWidht
  ctx.lineWidth = width;

  // add stroke to the line
  ctx.stroke();

  // Close the path
  ctx.closePath();
}
